import { useState, useMemo } from 'react'
export function useModalState () {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { handleClose, handleOpen } = useMemo(() => {
    return {
      handleClose () {
        setIsModalVisible(false)
      },
      handleOpen () {
        setIsModalVisible(true)
      }
    }
  }, [])
  return { isModalVisible, handleClose, handleOpen }
}
